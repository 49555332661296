#body{
    width: 100%;
}

.imagenes {
    width: 90%;
}
.container_modal-content {
    overflow-X: auto; 
    width:100%;
    margin-top:100px; 
    margin-left: 5%;
    margin-right: 5%;
    
}

@media only screen and (max-width: 767px) {
    .container_modal-content {
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 350px;
    }
    .imagenes {
        width: 100%;
    }
}