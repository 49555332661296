.contenedor {
    display: flex;
    justify-content: center;
  }
  
  #tabla-taller{
    margin-top: 150px ; 
  }

  #tabla-retails{
    margin-top:100px ;
  }

  /*-------------------------------------------------------------------------------*/

  .menu-btn{
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: #000;
    background:linear-gradient(90deg,#f5f5f6 0%, #f5f2ff 100%);
    line-height:0 ;
    cursor: pointer;
    transition:  all 0.4s ease;
    display: none;
}
.menu-btn:hover {
  background: #cdcbd4 ;
}

  .mobile-menu {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0,0,0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999 !important;
    opacity: 0;
    box-shadow: 0px 29px 80px  rgba(0, 0,0,0.3);
    transition: all 0.3s ease;
    transform: translateX(-100vw);

}

.mobile-menu-container{
    width: 60vw;
    height: 100vh;
    background-color: #ffffff;
    padding: 2rem;
}

.mobile-menu.active{
    opacity: 1 !important;
    transform: translateX(0);
    transition: all 0.3s ease;
}

.mobile-menu ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
    margin-left:-2rem ;
    padding: 0.5rem;
}

.mobile-menu ul li{
font-size: 20px;
}

.menu-item{
  text-decoration: none;
  font-weight: 500;
  color: #000;
  position: relative;
  cursor: pointer;
}

.menu-item::before{
  content: " ";
  width: 2rem;
  height: 0.5rem;
  background: linear-gradient(90deg,#93a1fe 0%, #6180e7 100%);
  border-radius: 0rem;
  position: absolute;
  bottom:-0.6rem ;
  opacity: 0;
  transform: translateX(-1.5rem);
  transition: all 0.3s ease;
}

.menu-item:hover::before{
  width: 100%;
  transform:translateX(0);
  opacity: 1;
}
#connectandlogout{
  margin: 1rem;
  gap: 1rem;
}

@media (max-width:769px) {
   .conteiner-menu-btn{
    padding-right:2rem ;
    display: flex;
    justify-content: end;
   }
    .menu-btn{
      display: block ;
  }
}
  
  /*-------------------------------------------------------------------------------*/
  @media (max-width: 760px) {
    .contenedor {
        display: flex;
        flex-direction: column;
    }
  }
  
  /* Estilos para pantallas grandes (PC) */
  @media (min-width: 768px) {
    .table-container {
      
      width: 100%;
    }
    .acordeon-container {
      display: none;
    }
  }
 
  
  
  /* Estilos para pantallas pequeñas (Móviles) */
  @media (max-width: 767px) {
    .table-container {
      display: none;
    }
    .acordeon-container {
      display: block;
    }
    .estatus {
      display: flex;
      justify-content: end;
    }
    #forum-retail{
      /* margin: 30px; */
      display: none;
    }
    #forum-remito{
      display: none;
    }
    .remito-filter{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
    }
    #filtro-remito{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;
      flex-direction: column;
    }
    #OrdenesService-colores{
       display: none;
    }
    #grey-card{
      height: 25rem;
    }

    #registro-taller{
      display: none;
    }
    #tabla-taller{
        margin-top:10px ;
    }
  #tabla-retails{
      margin-top:10px ;
    }

 
    
  }

  @media (max-width:1600px) {
    #LogoEmpresa {
      display:none;

    }
    #navbar1600{ 
      margin-left: 2rem;
    }
    #connectandlogout{
      margin: 0.5rem;
      gap: 5px;
    }
    
  }
  @media (max-width: 767px) {
    #navbarNav {
      padding-left: 10rem;
     justify-content: end;
    }
    
    
   
  }

  @media (min-width: 768px) {
    #navbarNav {
      padding-left: 0;
    }
  }

.eyeIcon {
    height: 30%;
    right: 3%;
    align-self: center;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
  }

.ocultar-div{
  display:block
}

  @media screen and (max-width: 760px){
    .ocultar-div{
      display:none
    }
  }
.image-upload > input
  {
      display: none;
  }
  
.image-upload img
  {
      cursor: pointer;
  }

  .passwordContainer {
    display: flex;
    position: relative;
    margin-bottom: 1.5rem;
}

.clrBorderRed {
  border-color: #ff0000 !important;
}

#nav {
  margin-top: 0 !important;
  transition: all 0.3s ease-in;

}
.navigation ul {
  width: 90%;
}
.navigation ul li {
  /* font-family: 'RobotoBoldCondensed'; */
  margin: 0;
  /* width: 14.285%; */
  width:90%
}
.navigation ul li a {
  border: 0;
  border-bottom: 6px solid transparent;
  color: #000000;
  padding: 10px 0;
  text-align: center;
  font-size: 17px;
}

.navigation ul li button {
  border: 0;
  border-bottom: 6px solid transparent;
  color: #000000;
  padding: 10px 0;
  text-align: center;
  font-size: 17px;
} 

.navigation ul li ul li{
  padding-top: 10px;
}

.responsive ul li a {
  border: 0;
  color: #000000;
  text-transform: uppercase;
  background: transparent;
}

.responsive ul li button {
  border: 0;
  color: #000000;
  text-transform: uppercase;
  background: transparent;
}
.navigation ul li ul{
  border-top: none;
  border-left:none;
  border-right: none;
  border: none;
}

.navigation ul li a.hogar {
  border-color: #e1802d;
}
.navigation ul li a.cocina {
  border-color: #a21f13;
}
.navigation ul li a.desayuno {
  border-color: #ecb74c;
}
.navigation ul li a.climatizacion {
  border-color: #008658;
}
.navigation ul li a.linea-blanca {
  border-color: #1d70a7;
}
.navigation ul li a.agua-caliente {
  border-color: #2e3182;
}
.navigation ul li a.movilidad {
  border-color: #000;
}
.navigation ul li button.movilidad{
  border-color: #e1802d;
}
.navigation ul li button.movilidad:hover,
.navigation ul li > button.movilidad.selected,
.navigation ul li.active >button.movilidad,
.responsive ul li button.movilidad,
.sub-1 {
    background: #e1802d;
    font-size: 18px;
    color: #ffffff;
    
}

.navigation ul li a:hover,
.navigation ul li > a.selected,
.navigation ul li.active > a {
    border-top: 0 !important;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4); */
}


.navigation ul li a.hogar:hover,
.navigation ul li > a.hogar.selected,
.navigation ul li.active > a.hogar,
.responsive ul li a.hogar,
.sub-4 {
    /* border-width: 10px;
    border-image: url(../images/v878-mind-49.eps) 30% repeat; */
    /* background: linear-gradient(45deg, #e1802d, #a4521a); */
    background: #e1802d;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.cocina:hover,
.navigation ul li > a.cocina.selected,
.navigation ul li.active a.cocina,
.responsive ul li a.cocina,
.sub-2 {
    background: #a21f13;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.desayuno:hover,
.navigation ul li > a.desayuno.selected,
.navigation ul li.active > a.desayuno,
.responsive ul li a.desayuno,
.sub-1 {
    background: #ecb74c;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.climatizacion:hover,
.navigation ul li > a.climatizacion.selected,
.navigation ul li.active > a.climatizacion,
.responsive ul li a.climatizacion,
.sub-6 {
    background: #008658;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.linea-blanca:hover,
.navigation ul li > a.linea-blanca.selected,
.navigation ul li.active > a.linea-blanca,
.responsive ul li a.linea-blanca,
.sub-5 {
    background: #1d70a7;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.agua-caliente:hover,
.navigation ul li > a.agua-caliente.selected,
.navigation ul li.active > a.agua-caliente,
.responsive ul li a.agua-caliente,
.sub-7 {
    background: #2e3182;
    font-size: 15px;
    color: #ffffff;
}
.navigation ul li a.movilidad:hover,
.navigation ul li > a.movilidad.selected,
.navigation ul li.active > a.movilidad,
.responsive ul li a.movilidad,
.sub-8 {
    background: #000;
    font-size: 15px;
    color: #ffffff;
}

/* Estilos para dispositivos móviles */
@media (max-width: 767px) {
  body {
    /* Aplica estilos específicos para dispositivos móviles */
    zoom: 100%;
  }
}

/* Estilos para pantallas de escritorio */
@media (min-width: 768px) {
  body {
    /* Aplica estilos específicos para pantallas de escritorio */
    zoom: 100%;
    
  }
}

.dropdown-menu-tabla { inset: auto auto auto -150% !important; }

.containerLabel {
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  background-color: thistle;
}

.container-remitente {
  display: flex;
  flex: 3;
  background-color: aquamarine;
}

.remitente {
  display: flex;
	transform: rotate(270deg);
	background-color: red;
}


.dato {
  margin: 5px;
  padding: 2px;
  background-color: springgreen;
}


.columfiltro {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.columfiltro > div {
  margin-right: 5px;
  margin-bottom: 5px;
}
.labelFiltro {
  margin-right:5px ;
  padding: 2px;
  display: flex;
}
.columfiltro > div:last-child {
  margin-right: 0;
}

.columfiltro p {
  margin: 0; /* Ajusta el margen del párrafo para evitar espacios adicionales */
}